<template>
	<div :class="themeClass">
		<div class="width-fill">
			<div class="login-t flex-row-align-center">
				<div class="font-weight700 font-size18 pointer" @click="goHome"><img :src="logoUrl" style="height: 54px;" /></div>
				<div class="font-color-E30000 font-weight700 font-size14 bg-E30000 flexUD pay-center">支付中心</div>
			</div>
		</div>
		<div style="padding:147px 0;">
			<div class="loginh"></div>
			<div class="login-c">
				<div class="">
					<div class="flex-row-start-start">
						<div class="result-message-box">
							<el-image style="width: 196px; height: 222px" :src="url"></el-image>
						</div>
						<div class="flex-colum result-box">
							<div v-if="examine||(payType!=2&&payType)" class="title-style">
								<div v-if="examine==1">{{successTypeName}}申请已提交，我们会尽快为您处理</div>
								<div v-if="payType==1">正在支付中，请稍后前往钱包查询本次交易记录</div>
								<div v-if="payType==3">{{successTypeName}}失败</div>
							</div>
							<div v-else class="title-style">
								<div>{{successTypeName}}成功</div>
							</div>
							<div class="flex-colum font-color-666" style="line-height: 35px;">
								<div v-if="billNumber!=''&&billNumber!=null">单号:{{billNumber}}</div>
								<div v-if="type!=2&&type!=3">{{successTypeName}}{{typeName}}:{{amount|NumFormat}}</div>
								<div v-if="type==2||type==3">{{successTypeName}}{{typeName}}:{{amount}}{{typeName}}</div>
							</div>
							<div class="flex-row">
								<div v-if="successType=='ZF'&&type!=2 && fDeliveryID != 4 && fDeliveryID != 5 && myselfCar!= 0" class="margin-t-20 border-theme color-theme flexUD pointer buttonStyle"
								 @click="toSendCars">
									去派车
								</div>
								<div v-if="successType=='ZF'||type==2||type==3|| type ==4" class="margin-l-15 margin-t-20 border-theme color-theme flexUD pointer buttonStyle"
								 @click="toAgain">
									继续购买
								</div>
								<div v-if="successType=='CZ'||successType=='TX'||successType=='HK'" class="margin-l-15 margin-t-20 border-theme color-theme flexUD pointer buttonStyle"
								 @click="goWallet">返回钱包</div>
								<div v-if="fDeliveryID != 2 ||   successType=='CZ'||successType=='TX'||successType=='HK'||type==2" class="margin-l-15 margin-t-20 border-theme color-theme flexUD pointer buttonStyle"
								 @click="goHome">返回首页</div>
								<!-- <div  class="margin-l-15 margin-t-20 border-theme color-theme flexUD pointer buttonStyle"
								>查询状态</div> -->

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		components: {},
		data() {
			return {
				fCustomerPaymentBillID:'',//判断接口需要的id
				successType: '', //成功类型 充值:CZ 提现:TX 还款:HK 支付:ZF
				successTypeName: '', //成功类型名字
				examine: null, //  1表示审核中 0表示初始化 254 表示审核通过
				payType: '', // 1 支付中 2支付成功 3支付失败
				billNumber: null, //单据编号
				amount: '', //金额
				type: '', //type: 单位类型：1金额2金币3积分
				typeName: '',
				fBillTypeID: '',
				shopId: '', //积分支付店铺ID
				url: require('../assets/imgs/PaymentSuccessful.png'),
				fDeliveryID: '', //如果==4就是小吨配送去派车就不显示
				logoUrl: '',
				myselfCar:'',
			};
		},
		created() {
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		},
		mounted() {
			this.fDeliveryID = sessionStorage.getItem('fDeliveryID');
			// fBillTypeID 为 200015019  账服通充值单
			// fBillTypeID 为 200000905  授信还款单
			// fBillTypeID 为 200000805  现金钱包充值
			// let data = JSON.parse(decodeURIComponent(this.$route.query.data));
			if (this.$route.query.data) {
				let data = JSON.parse(this.$route.query.data);
				console.log(data,'查看数据到底传过来没')
				this.fCustomerPaymentBillID = data.fCustomerPaymentBillID
				this.ispaiche()
				this.type = data.type; //type: 单位类型：1金额2金币3积分
				this.amount = data.amount; //金额,金币数量,积分数量
				this.successType = data.successType ? data.successType : ''; //成功类型 充值:CZ 提现:TX 还款:HK 支付:ZF
				this.billNumber = data.billNumber ? data.billNumber : ''; //单据编号
				this.examine = data.examine ? data.examine : ''; //  1表示审核中 0表示初始化 254 表示审核通过
				this.payType = data.payType ? data.payType : ''; // 1 支付中 2支付成功 3支付失败
				this.fBillTypeID = data.fBillTypeID ? data.fBillTypeID : '';
				this.shopId = data.shopId ? data.shopId : ''; //积分支付店铺ID
				console.log('this.shopId', this.shopId);
			}
			if (this.successType == 'CZ') {
				this.successTypeName = '充值'
			} else if (this.successType == 'TX') {
				this.successTypeName = '提现'
			} else if (this.successType == 'HK') {
				this.successTypeName = '还款'
			} else {
				this.successTypeName = '支付'
			}
			if (this.type == 2) {
				this.typeName = '金币'
			} else if (this.type == 3) {
				this.typeName = '积分'
			} else {
				this.typeName = '金额'
			}
		},
		computed: {
			...mapGetters(['getThemeName', "getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//判断去派车按钮是否显示
			ispaiche(){
				this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/get-customerpaybill-is-delivery', {
						fCustomerPaymentBillID: this.fCustomerPaymentBillID,
					})
					.then(res => {
						console.log(res,'00000000')
						this.myselfCar = res.obj
					}, error => {
					});
			},
			//去派车
			toSendCars() {
				this.$router.replace({
					path: '/businessme/DispatchOrder',
					query: ''
				});
			},
			//再来一单
			toAgain() {
				if (this.type == 2) {
					this.$router.replace({ //金币商城
						path: '/goldMall',
						query: ''
					});
				} else if (this.type == 3) { //积分支付去店铺页面
					this.$router.replace({
						path: '/storeList',
						query: {
							id: this.shopId
						}
					})
				} else if(this.type == 4){  //去积分购
					this.$router.replace({
						path: '/pointPurchase',
						query: ""
					})
				}else {
					this.$router.replace({ //商品列表
						path: '/Goods',
						query: ''
					});
				}
			},
			//返回钱包
			goWallet() {
				if (this.fBillTypeID == '200015019') { //账服通充值单
					this.$router.replace({ //金币商城
						path: '/businessme/AccountPay',
					});
				} else if (this.fBillTypeID == '200000905') { //授信还款单
					this.$router.replace({
						path: '/businessme/CreditLine',
					});
				} else if (this.fBillTypeID == '200000805') { //现金钱包充值
					this.$router.replace({
						path: '/businessme/MoneyDash',
					});
				}
			},
			//返回首页
			goHome() {
				if (this.type == 2) { //金币商城首页
					this.$router.replace({
						path: '/goldMall',
						query: ''
					});
				} else {
					this.$router.replace({ //商城首页
						path: '/home',
						query: ''
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.result-box {
		margin-left: 66px;
		margin-top: 24px;
		width: 100%;
	}

	.pay-center {
		width: 69px;
		height: 22px;
		margin-left: 25px;
	}

	.result-message-box {
		width: 196px;
		height: 222px;
		margin-top: 11px;
		margin-left: 47px;
	}

	.title-style {
		color: #3FD09E;
		font-size: 20px;
		font-weight: bold;
		line-height: 35px;
	}

	.login-t {
		font-weight: 700;
		font-size: 18px;
		// line-height: 80px;
		height: 80px;
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		/*垂直居中*/
		background-color: #ffffff;
	}

	.loginh {
		margin-top: 80px;
		background: url(../assets/imgs/payResult.png);
		// background: url(../assets/imgs/payBackground.jpg) ;
		width: 100%;
		height: 570px;
		background-repeat: no-repeat;
		background-size: cover;
		// -webkit-filter:blur(15px);
		// -moz-filter:blur(15px);
		// -o-filter:blur(15px);
		// -ms-filter:blur(15px);
		// filter:blur(15px);
		position: absolute;
		left: 0;
		top: 0;
		z-index: -999;
	}

	.login-c {
		margin: 0 auto;
		width: 900px;
		height: 253px;
		border-radius: 10px;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.149019607843137);
		background-color: #ffffff;
	}

	/* 单选地址 */

	.flexUD {
		display: flex;
		align-items: center;
		/*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/*  */
	.font-color-E30000 {
		color: #e30000;
	}

	.bg-E30000 {
		background-color: #e30000;
		color: #ffffff;
	}

	/*  */
	.buttonStyle {
		width: 160px;
		height: 40px;
		background-color: #ffffff;
		border-radius: 2px;
	}

	// ::v-deep #app .el-checkbox__inner {
	// 	border-radius: 10px;
	// }
</style>
